var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{attrs:{"wrap":"","fill-height":"","justify-center":"","pt-8":"","px-2":"","px-md-0":""}},[_c('v-flex',{attrs:{"xs12":"","text-center":""}},[_c('span',{staticStyle:{"font-family":"LexendMedium","font-weight":"500"},style:({'font-size': 
                 _vm.$vuetify.breakpoint.name == 'xs'
                ? '25px'
                : _vm.$vuetify.breakpoint.name == 'sm'
                ? '25px'
                : _vm.$vuetify.breakpoint.name == 'md'
                ? '30px'
                : _vm.$vuetify.breakpoint.name == 'lg'
                ? '30px'
                : '30px', }),attrs:{"data-aos":"zoom-in-up","data-aos-ease":"ease","data-aos-duration":"1500","data-aos-delay":"500"}},[_vm._v("Hear What Our Guests Love About Their Houseboat Escapes")])]),_c('v-flex',{attrs:{"xs12":"","sm10":"","md6":"","text-center":""}},[_c('span',{staticStyle:{"font-family":"LexendRegular","font-weight":"200"},style:({'font-size': 
                 _vm.$vuetify.breakpoint.name == 'xs'
                ? '14px'
                : _vm.$vuetify.breakpoint.name == 'sm'
                ? '14px'
                : _vm.$vuetify.breakpoint.name == 'md'
                ? '16px'
                : _vm.$vuetify.breakpoint.name == 'lg'
                ? '16px'
                : '16px', }),attrs:{"data-aos":"zoom-in-up","data-aos-ease":"ease","data-aos-duration":"1500","data-aos-delay":"500"}},[_vm._v(" Genuine insights from fellow travelers as they share their firsthand experiences, ensuring you make informed decisions for your perfect houseboat getaway.")])]),_c('v-flex',{attrs:{"xs12":""}},[_c('v-img',{attrs:{"src":require("./../assets/images/bg1.png"),"width":"100%","height":_vm.$vuetify.breakpoint.name == 'xs' 
                ? '1000px' 
                :_vm.$vuetify.breakpoint.name == 'sm'
                ? '400px'
                : _vm.$vuetify.breakpoint.name == 'md'
                ? '360px'
                : _vm.$vuetify.breakpoint.name == 'lg'
                ? '400px': '600px'}},[_c('v-layout',{attrs:{"wrap":"","fill-height":"","justify-center":"","mx-4":""}},[_c('v-flex',{staticClass:"text-center",attrs:{"xs12":"","lg9":"","pt-2":"","align-self-start":"","data-aos":"zoom-in-up","data-aos-ease":"ease","data-aos-duration":"1500","data-aos-delay":"500"}},[_c('v-layout',{attrs:{"wrap":""}},_vm._l((_vm.allreviews),function(item,i){return _c('v-flex',{key:i,attrs:{"xs12":"","sm4":"","md4":"","px-2":"","py-2":""}},[_c('v-card',{staticClass:"pa-6 tstc",staticStyle:{"border-radius":"20px"},attrs:{"elevation":"1"}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","pt-2":"","text-left":""}},[_c('v-rating',{attrs:{"background-color":"rgba(241, 115, 67, 1)","color":"rgba(241, 115, 67, 1)","small":""},model:{value:(item.rating),callback:function ($$v) {_vm.$set(item, "rating", $$v)},expression:"item.rating"}})],1),_c('v-flex',{attrs:{"xs12":"","pt-2":"","px-2":"","text-left":""}},[_c('span',{staticStyle:{"font-family":"LexendFont","font-weight":"500","font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.truncateText(item.comment, 35)))])])],1),_c('v-layout',{attrs:{"wrap":"","pt-6":"","justify-center":""}},[_c('v-flex',{attrs:{"xs2":"","pt-1":""}},[_c('v-img',{attrs:{"contain":"","src":require("./../assets/images/user.jpg")},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,true)})],1),_c('v-flex',{staticStyle:{"line-height":"15px"},attrs:{"xs9":"","px-2":"","text-left":"","align-self-center":""}},[_c('span',{staticStyle:{"font-family":"LexendFont","font-weight":"700","font-size":"14px"}},[_vm._v(_vm._s(item.user.name))]),_c('br')])],1)],1)],1)}),1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }