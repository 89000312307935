var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{attrs:{"wrap":"","fill-height":"","py-2":"","justify-center":"","px-4":"","px-md-0":""}},[_c('v-flex',{attrs:{"xs12":"","text-center":"","px-0":"","px-sm-2":"","px-md-8":"","data-aos":"fade-left","data-aos-delay":"100"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","text-center":"","pb-4":""}},[_c('span',{staticStyle:{"font-family":"LexendMedium","font-weight":"500"},style:({
              'font-size':
                _vm.$vuetify.breakpoint.name == 'xs'
                  ? '25px'
                  : _vm.$vuetify.breakpoint.name == 'sm'
                  ? '25px'
                  : _vm.$vuetify.breakpoint.name == 'md'
                  ? '30px'
                  : _vm.$vuetify.breakpoint.name == 'lg'
                  ? '30px'
                  : '30px',
            })},[_vm._v(" Immerse Yourself in Tranquil Houseboat Escapes in Kerala")])]),_c('v-flex',{staticStyle:{"z-index":"1"},attrs:{"xs12":"","sm9":"","xl6":"","text-center":""}},[_c('iframe',{attrs:{"height":_vm.$vuetify.breakpoint.name == 'xs'
                      ? '290px'
                      : _vm.$vuetify.breakpoint.name == 'sm'
                      ? '400px'
                      : _vm.$vuetify.breakpoint.name == 'md'
                      ? '300px'
                      : _vm.$vuetify.breakpoint.name == 'xl'
                      ? '500px'
                      : _vm.$vuetify.breakpoint.name == 'lg'
                      ? '440px'
                      : '440px',"width":_vm.$vuetify.breakpoint.name == 'xs'
                      ? '290px'
                      : _vm.$vuetify.breakpoint.name == 'sm'
                      ? '590px'
                      : _vm.$vuetify.breakpoint.name == 'md'
                      ? '450px'
                      : _vm.$vuetify.breakpoint.name == 'xl'
                      ? '1000px'
                      : _vm.$vuetify.breakpoint.name == 'lg'
                      ? '680px'
                      : '680px',"src":'https://www.youtube.com/embed/' + _vm.videoLink + '?rel=0',"frameborder":"1","allowfullscreen":"","ng-show":"showvideo"}})])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }