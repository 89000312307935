var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-element-loading',{attrs:{"active":_vm.appLoading,"is-full-screen":true,"background-color":"#FFFFFF","color":"#182444","spinner":"bar-fade-scale"}}),(_vm.ServerError)?_c('ServerError'):_vm._e(),_c('v-snackbar',{attrs:{"color":"#182444","right":"","timeout":"2000"},model:{value:(_vm.showsnackbar),callback:function ($$v) {_vm.showsnackbar=$$v},expression:"showsnackbar"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{staticClass:"align-self-center",attrs:{"text-left":""}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.msg)+" ")])]),_c('v-flex',{attrs:{"text-right":""}},[_c('v-btn',{attrs:{"small":"","ripple":false,"text":""},on:{"click":function($event){_vm.showsnackbar = false}}},[_c('v-icon',{staticStyle:{"color":"#000"}},[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-img',{attrs:{"src":require("./../assets/images/bg.png"),"width":"100%","height":_vm.$vuetify.breakpoint.name == 'xs'
        ? '2600px'
        : _vm.$vuetify.breakpoint.name == 'sm'
        ? '1700px'
        : _vm.$vuetify.breakpoint.name == 'md'
        ? '1500px'
        : _vm.$vuetify.breakpoint.name == 'lg'
        ? '1200px'
        : '1200px'}},[_c('v-layout',{attrs:{"wrap":"","fill-height":"","justify-center":""}},[_c('v-flex',{attrs:{"xs11":"","sm11":"","lg10":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticStyle:{"line-height":"30px"},attrs:{"xs12":"","pt-4":"","pt-sm-8":"","pt-md-0":"","data-aos":"zoom-in-up","data-aos-ease":"ease","data-aos-duration":"1500","data-aos-delay":"500"}},[_c('span',{staticStyle:{"font-family":"LexendMedium","font-weight":"400","color":"rgba(255, 98, 0, 1)","text-transform":"uppercase"},style:({
                    'font-size':
                      _vm.$vuetify.breakpoint.name == 'xs'
                        ? '16px'
                        : _vm.$vuetify.breakpoint.name == 'sm'
                        ? '25px'
                        : _vm.$vuetify.breakpoint.name == 'md'
                        ? '30px'
                        : _vm.$vuetify.breakpoint.name == 'lg'
                        ? '30px'
                        : '45px',
                  })},[_vm._v("Welcome To ")]),_c('br'),_c('span',{staticStyle:{"font-family":"LexendMedium","font-weight":"600","color":"white"},style:({
                    'font-size':
                      _vm.$vuetify.breakpoint.name == 'xs'
                        ? '25px'
                        : _vm.$vuetify.breakpoint.name == 'sm'
                        ? '20px'
                        : _vm.$vuetify.breakpoint.name == 'md'
                        ? '40px'
                        : _vm.$vuetify.breakpoint.name == 'lg'
                        ? '40px'
                        : '50px',
                  })},[_vm._v("Access Rooms "),_c('br'),_vm._v("Book Your Houseboat/ Shikara Adventure Now!")])])],1)],1),_c('v-flex',{attrs:{"xs12":"","pt-4":""}},[_c('v-card',{attrs:{"color":"transparent","elevation":"0"}},[_c('v-tabs',{attrs:{"background-color":"transparent","color":"rgba(255, 98, 0, 1)","left":"","data-aos":"slide-down","data-aos-ease":"ease","data-aos-duration":"2500","data-aos-delay":"500"},model:{value:(_vm.myTab),callback:function ($$v) {_vm.myTab=$$v},expression:"myTab"}},[_c('v-tab',{staticStyle:{"border-top-left-radius":"10px"},style:(_vm.myTab == 0
                      ? 'background-color:rgba(255, 98, 0, 1)'
                      : 'background-color:white')},[_c('v-icon',{staticClass:"pr-1",style:(_vm.myTab == 0 ? 'color:white' : 'color:black')},[_vm._v("mdi-ferry")]),_c('span',{staticStyle:{"font-family":"RobotoBold","font-weight":"500","font-size":"14px"},style:(_vm.myTab == 0 ? 'color:white' : 'color:black')},[_vm._v("Houseboat")])],1),_c('v-tab',{staticStyle:{"border-top-right-radius":"10px"},style:(_vm.myTab == 1
                      ? 'background-color:rgba(255, 98, 0, 1)'
                      : 'background-color:white')},[_c('v-icon',{staticClass:"pr-1",style:(_vm.myTab == 1 ? 'color:white' : 'color:black')},[_vm._v("mdi-sail-boat")]),_c('span',{staticStyle:{"font-family":"RobotoBold","font-weight":"500","font-size":"14px"},style:(_vm.myTab == 1 ? 'color:white' : 'color:black')},[_vm._v("shikara")])],1),_c('v-tab-item',[_c('houseboatFilter')],1),_c('v-tab-item',[_c('shikaraFilter')],1)],1)],1)],1)],1)],1)],1)],1),_c('v-layout',{attrs:{"wrap":"","py-4":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('PopularDestinations')],1)],1),_c('v-layout',{attrs:{"wrap":"","py-4":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","md10":""}},[_c('HomeSection3')],1)],1),_c('v-layout',{attrs:{"wrap":"","py-4":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('HomeSection4')],1)],1),_c('v-layout',{attrs:{"wrap":"","py-4":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('HomeSection5')],1)],1),_c('HomeSection2')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }